<template>
    <ul :class="listCssClass" ref="links">
        <router-link tag="li" v-for="(route, index) in routes" :key="index + '-' + currentLanguageCode" :to="'/'+currentLanguageCode+route.path" v-slot="{ href, isExactActive }">
            <li v-if="route.shortName" 
                :class="'page-'+route.shortName+(itemCssClass ? ' '+itemCssClass : '')+(isRouteActive(isExactActive, route) ? ' '+activeCssClass : '')" 
                :data-anchor-path="getAnchorPath(route)">
                <a 
                    :href="href" 
                    @click="linkClick($event, route)" 
                    :class="linkCssClass"><div>{{ getLocalizedText(route.label) }}</div></a>
                <ListLinks v-if="route.subRoutes" 
                    :listCssClass="sublistCssClass"
                    :itemCssClass="itemCssClass"
                    :activeCssClass="activeCssClass"
                    :linkCssClass="linkCssClass"
                    :routes="route.subRoutes" />
            </li>
            <li v-else 
                :class="itemCssClass">
                <a 
                    :href="route.path" 
                    @click="linkClick($event, route)" 
                    :class="linkCssClass"><div>{{ getLocalizedText(route.label) }}</div></a>
            </li>
        </router-link>
    </ul>
</template>

<script lang="ts">
import { mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { languagesTypes } from '@fwk-client/store/types';
import LinksMixin from './LinksMixin.vue'

@Component({
  components : {
      ListLinks
  },
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
        })
  }
})
export default class ListLinks extends mixins(LinksMixin) {

    /**
     * listCssClass
     * css class of the list
     */
    @Prop({
        type: String,
        required: false,
        default: '',
    }) readonly listCssClass!: string;

    /**
     * sublistCssClass
     * css class of the sub list
     */
    @Prop({
        type: String,
        required: false,
        default: '',
    }) readonly sublistCssClass!: string;

    /**
     * itemCssClass
     * css class of the list item
     */
    @Prop({
        type: String,
        required: false,
        default: null,
    }) readonly itemCssClass!: string;

    
}
</script>