<template>
    <nav class="primary-menu">
        <ListLinks
            listCssClass="menu-container"
            sublistCssClass="sub-menu-container"
            itemCssClass="menu-item"
            activeCssClass="current"
            linkCssClass="menu-link"
            :routes="routes"
        >
        </ListLinks>
    </nav>
</template>

<style>

body.primary-menu-open .menu-container {
    display: block;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import ListLinks from './ListLinks.vue';

@Component({
  components : {
      ListLinks
  }
})
export default class TopLinks extends Vue {
    
    /**
     * route : {
     *  path:string
     *  shortName:string
     *  label:string
     * }
     */
    @Prop({
        type: Array,
        required: true,
    }) readonly routes!: any[]

}
</script>