<template>
    <div id="top-cart" :class="topCartClass" v-if="totalProducts.length > 0 || !computedOptions.hideWhenEmpty">
        <a href="javascript:void(0)" @click="onCartClick()">
            <i class="icon-shopping-cart"></i>
            <span class="top-cart-number">{{totalProducts}}</span>
        </a>
    </div>
</template>

<style>

#top-cart i.icon-shopping-cart {
    font-size: 20px;
}

#top-cart.header-misc-icon > a {
    height:22px;
    line-height: 22px;
    transition: color 0s ease-in-out;
}

#top-cart .top-cart-number {
    top: -11px;
    left: 13px;
    font-size: 14px;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

/*
.top-cart-items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
*/

/*
.top-cart-content .top-cart-action .top-checkout-price {
    margin-right:20px;
}

/* We apply specific style for top cart content when opened as tooltip */
/*
@media (min-width: 992px) {
    .top-cart-content {
        opacity: 0;
        z-index: -2;
        position: absolute;
        width: auto;
        background-color: var(--content-background-color);
        box-shadow: 0px 20px 50px 10px rgba(0, 0, 0, 0.05);
        border: 1px solid #EEE;
        border-top: 2px solid var(--secondary-color);
        right: -15px;
        left: auto;
        top: calc(100% + 2px);
        margin: -10000px 0 0;
        -webkit-transition: opacity .5s ease, top .4s ease;
        -o-transition: opacity .5s ease, top .4s ease;
        transition: opacity .5s ease, top .4s ease;
    }
}

/* We apply specific style for top cart content when opened as fixed from the right */
/*
@media (max-width: 991.8px) {
    .top-cart-open .top-cart-content {
        width: auto;
    }
}

.top-cart-content .top-cart-title .btn-close {
    display: none;
}

@media (max-width: 991px) {
    .top-cart-content .top-cart-title .btn-close {
        display: block;
        position:absolute;
        top:10px;
        right:10px;
    }
}
*/

</style>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, Ref, ref, computed } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useCart } from '@root/src/client/composables/useCart'
import { utils } from '@fwk-client/router/utils';

/** @cmsOptions */
export interface TopCartOptions {
    /** @cmsType CmsBoolean */
    hideWhenEmpty?:CmsBoolean;
    /** @cmsType CmsEnum */
    type?:CmsEnum<"shop"|"hospitality">,
}

/** @cmsLabels */
export interface TopCartLabels {
}

/** @cmsSlots */
export interface TopCartSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<TopCartOptions>,
        labels: {
          type: Object as PropType<TopCartLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<TopCartSlots>
    },
    components : {
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:TopCartOptions = {
            type: "shop",
            ...props.options,
        };

        const { cartProducts, cartActivities } = useCart({
            type: computedOptions.type!
        }, context);

        const computedLabels:TopCartLabels = {
            ...props.labels
        }

        const totalProducts = computed(() => {
            return cartProducts.value.length + cartActivities.value.length;
        })

        const currentLanguageCode = computed(() => {
			return $store.state.languages.currentLanguageCode;
		})

        const topCartClass = computed(() => {
            return app.$shop_isTopCartDisplayed ? "header-misc-icon top-cart-open" : "header-misc-icon"
        })

        const onCartClick = (() => {
            app.$shop.cart.toggleSideCart();
        })

        return {
            currentLanguageCode,
            topCartClass,
            computedLabels,
            computedOptions,
            totalProducts,
            onCartClick
        }
    }
})

</script>