<template>
    <div class="header-misc-element dropdown language-selector" ref="languageSelector" v-if="availableLanguages.length > 1">
        <a role="button" aria-haspopup="true" aria-expanded="false" href="#" target="_self" class="dropdown-toggle menu-link" data-bs-toggle="dropdown" id="language-selector-link">
            <img :src="require('@public/img/vigneron-online/flags/'+currentLanguageCode+'.png')" :alt="$t('languages.'+currentLanguageCode)"/>
        </a>
        <ul tabindex="-1" class="dropdown-menu" aria-labelledby="language-selector-link"> 
            <li v-for="(languageCode, index) in availableLanguages" :key="index" @click="onLanguageClick(languageCode)" :data-language="languageCode" role="presentation">
                <a role="menuitem" href="javascript:void(0)" target="_self" class="dropdown-item" >
                    <img :src="require('@public/img/vigneron-online/flags/' + languageCode + '.png')" :alt="$t('languages.'+languageCode)" /> {{$t('languages.'+languageCode)}}
                </a>
            </li>
        </ul>
    </div>
</template>

<style>

.language-selector ul.dropdown-menu {
    background-color: #fff !important;
    box-shadow: 0px 13px 42px 11px rgba(0,0,0,.05);
    border: 1px solid #eee;
    border-top: 2px solid var(--secondary-color);
    margin: 0;
    padding: 0;
    width:125px;
    min-width: 125px;
    transition: none;
    border-radius: 0;
}

.language-selector ul.dropdown-menu a:hover {
    padding-left:18px;
}

.language-selector ul.dropdown-menu a {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    padding: 10px 15px;
    border: 0;
    letter-spacing: 0;
    font-family: 'Lato',sans-serif;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    line-height: 22px;
    text-transform: uppercase;
}

.language-selector ul.dropdown-menu a img {
    margin-right: 10px;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';
import { Dropdown } from 'bootstrap';

@Component({
  components : {
      
  },
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
      ...mapState({
          availableLanguages: (state:any) => state.languages.availableLanguages
        })
  }
})
export default class LanguageSelector extends Vue {
    
    mounted() {
        if(this.$store.state.languages.availableLanguages.length > 1) {
            // We enable the language selector dropdown
            // @ts-ignore
            var dropdownToggleEl = this.$refs.languageSelector.querySelector('.dropdown-toggle');
            new Dropdown(dropdownToggleEl);
        }
    }

    onLanguageClick(languageCode:string) {
        // We redirect to the new language path
        //var path = utils.getRoutePathWithLanguage(this.$store.state.route, languageCode);
        var route = { params: { lang: languageCode } };
        this.$router.push(route).catch(err => {
            console.log(err);
        });
    }
}
</script>