<template>
    <div class="header-misc-element dropdown top-user" ref="topUser">
        <a role="button" aria-haspopup="true" aria-expanded="false" href="#" target="_self" class="dropdown-toggle menu-link" data-bs-toggle="dropdown" id="language-selector-link">
            <i class="icon-user"></i>
        </a>
        <ListLinks
            listCssClass="dropdown-menu"
            activeCssClass="current"
            linkCssClass="dropdown-item"
            :routes="routes"
        >
        </ListLinks>
    </div>
</template>

<style>

.language-selector ul.dropdown-menu {
    background-color: #fff !important;
    box-shadow: 0px 13px 42px 11px rgba(0,0,0,.05);
    border: 1px solid #eee;
    border-top: 2px solid var(--secondary-color);
    margin: 0;
    padding: 0;
    width:125px;
    min-width: 125px;
    transition: none;
    border-radius: 0;
}

.language-selector ul.dropdown-menu a:hover {
    padding-left:18px;
}

.language-selector ul.dropdown-menu a {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    padding: 10px 15px;
    border: 0;
    letter-spacing: 0;
    font-family: 'Lato',sans-serif;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

    line-height: 22px;
    text-transform: uppercase;
}

.language-selector ul.dropdown-menu a img {
    margin-right: 10px;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';
import { Dropdown } from 'bootstrap';
import ListLinks from './ListLinks.vue';
import { types as applicationTypes } from '@root/src/client/store/application';

@Component({
  components : {
      ListLinks
  },
  computed: {
      ...mapGetters({
        currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
          isLoggedIn : 'authentication/' + authenticationTypes.getters.IS_LOGGED_IN,
          user : 'authentication/' + authenticationTypes.getters.GET_USER
        }),
      ...mapState({
          availableLanguages: (state:any) => state.languages.availableLanguages
        })
  }
})
export default class TopUser extends Vue {
    
    mounted() {
        
        // We enable the top userdropdown
        // @ts-ignore
        var dropdownToggleEl = this.$refs.topUser.querySelector('.dropdown-toggle');
        new Dropdown(dropdownToggleEl);
    }

    get routes() {
        // We get the routes in menu
        var routes = this.$store.getters['application/' + applicationTypes.getters.GET_ROUTES_IN_MENU](this, "topUser");
        return routes;
    }
}
</script>