<template>
    <header id="header" :class="headerCssClass" :data-sticky-class="stickyClass" data-sticky-shrink-offset="100">
      <div id="header-wrap">
        <div class="container-fluid">
          <div class="header-row">

            <!-- Logo
            ============================================= -->
            
            <div v-if="logoURL !== undefined" id="logo" :class="logoClass">
              <router-link :to="{ name : 'home', params : { lang : currentLanguageCode }}" class="standard-logo" :data-dark-logo="darkLogoURL" :data-default-logo="logoURL">
                <img :src="isDarkHeader ? darkLogoURL : logoURL" :alt="$cms.utils.getComputedLabel(labels.logoAlt)" />
              </router-link>
              <router-link :to="{ name : 'home', params : { lang : currentLanguageCode }}" class="retina-logo" :data-dark-logo="darkLogoURL" :data-default-logo="logoURL">
                <img :src="isDarkHeader ? darkLogoURL : logoURL" :alt="$cms.utils.getComputedLabel(labels.logoAlt)" />
              </router-link>
            </div>
            <!-- #logo end -->

            <div class="header-misc">
                <TopCartIcon v-if="showCart" 
                  :options="topCartOptions"
                />
                <LanguageSelector></LanguageSelector>
                <TopUser v-if="isLoggedIn"></TopUser>
            </div>

            <div id="primary-menu-trigger" @click="onMenuTrigger()">
              <svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>  
            </div>

            <TopLinks :routes="routes"></TopLinks>
          </div>
        </div>
      </div>
      <div class="header-wrap-clone"></div>
    </header>
</template>

<style>

@media (min-width: 992px) {
  /* We ensure that header wrap clone has same size as initial header */
  #header.square-logo .header-wrap-clone {
    height:60px;
  }

  /* We ensure that content to be included within header (ex: slider) has the right margin */
  #header.square-logo + .include-header {
		margin-top: calc( -60px - 2px );
  }

  #header.transparent-header.dark.square-logo #logo {
    background-color:transparent;
    border-color:transparent;
    box-shadow:none;
  }
}

/* We ensure that links, language selector and top cart are pushed to the right */
#header .header-row {
  justify-content: flex-end;
}

/* We position the logoc in absolute position on top left */
#header.full-header.square-logo #logo {
  position: absolute;
  left: 71px;
  top: 20px;
  height: 200px;
  width: 200px;
  max-height: none;
  padding: 5px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.075);
  border-radius: 3px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  z-index: 15;

  transition: height .4s ease, width .4s ease, top .4s ease,margin .4s ease,opacity .2s .2s ease;
}

@media (max-width: 991.98px) {

  /* We keep the logo to the left and reduce size */
  #header.full-header.square-logo #logo {
    left:50px;
    height: 150px;
    width: 150px;
  }

  /* We reduce size of the logo when menu is opened */
  body.primary-menu-open #header.full-header.square-logo #logo {
    top:0px;
    height: 100px;
    width: 100px;
  }

  /* We move the menu down to be just below the logo (60px header banner + 40px margin = 100px logo height) */
  body.primary-menu-open #header.full-header .primary-menu {
    margin-top: 40px;
  }
}

@media (max-width: 575.98px) {
  /* We move back the logo to the left */
  #header.full-header.square-logo #logo {
    left:0px;
    transform: none;
  }
}

/* We set specific size for the shop logo */
#header.full-header.sticky-header.square-logo #logo,
#header.full-header.square-logo.square-mini #logo {
  height:100px;
  width:100px;
}

/* We set specific size for the shop logo */
#header.full-header.square-logo #logo img {
  height:auto!important;
  padding:0!important;
}

/* We ensure that the header is reduced */
#header.square-logo .dropdown .menu-link,
#header.square-logo #top-cart.header-misc-icon {
  padding-top: 17px;
  padding-bottom: 17px; 
}

/* We ensure that the header is reduced */
#header.square-logo .menu-container > .menu-item > .menu-link {
  padding-top: 19px;
  padding-bottom: 19px; 
}
  
</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import type { Ref } from '@fwk-node-modules/vue';
import { types as applicationTypes } from '../../../../store/application';
import { authenticationTypes, languagesTypes, metaTypes } from '@fwk-client/store/types';

import LanguageSelector from '@root/src/client/components/panels/layouts/common/LanguageSelector.vue';
import TopCartIcon, { TopCartOptions } from '@root/src/client/components/cms/canvas/shop/TopCartIcon.vue';
import TopUser from '@root/src/client/components/panels/layouts/common/TopUser.vue';
import TopLinks from '@root/src/client/components/panels/layouts/common/TopLinks.vue';
import { useStore, getApp, useCmsModule } from '@fwk-client/utils/vue-3-migration';
import { Component } from '@fwk-client/modules/cms/helpers/components';
import { CmsPicture, CmsLabel, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface HeaderOptions {
  /** @cmsType CmsPicture */
  logoURL?:CmsPicture,
  /** @cmsType CmsPicture */
  darkLogoURL?:CmsPicture,
  /** @cmsType CmsEnum */
  style?:CmsEnum<"square"|"square-mini"|"default">,
  /** @cmsType CmsBoolean */
  topCartOptions?:TopCartOptions
}

/** @cmsLabels */
export interface HeaderLabels {
  /** @cmsType CmsLabel */
  logoAlt?:CmsLabel;
}

export default defineComponent({
    props: {
        options: Object as PropType<HeaderOptions>,
        labels: {
          type: Object as PropType<HeaderLabels>,
          default: () => { return {} }
        },
        slider: [Object, String, Function, Array] as PropType<Component|Component[]>
    },
    components: {
      LanguageSelector,
      TopLinks,
      TopUser,
      TopCartIcon
    },
    setup(props, context) {
        const store = useStore();
        const cms = useCmsModule();

        const computedOptions:HeaderOptions = {
          style:"default",
          ...props.options,
          logoURL : (props.options && props.options.logoURL) ? cms.utils.getComputedPictureURL(props.options.logoURL) : undefined,
          darkLogoURL : (props.options && props.options.darkLogoURL) ? cms.utils.getComputedPictureURL(props.options.darkLogoURL) : undefined
        };

        const isDarkHeader = computed(() => {
          if(props.slider != undefined) {
            var sliderComponent = Array.isArray(props.slider) ? props.slider[0] : props.slider;
            var sliderProps = cms.components.getComponentProps(sliderComponent);
            if(sliderProps && sliderProps.options && sliderProps.options.includeHeader !== false) {
              return true;
            }
          }
          return false;
        })

        const headerCssClass = computed(() => {
          var cssClass = "full-header";
          // We check if we have slider
          if(isDarkHeader.value) {
            cssClass += " transparent-header dark";
          }
          if(computedOptions.style && computedOptions.style.startsWith("square")) {
            cssClass += " square-logo";
            if(computedOptions.style == "square-mini") {
              cssClass += " square-mini";
            }
          }
          return cssClass
        })

        const stickyClass = computed(() => {
          var stickyClass = undefined;
          if(props.slider != undefined) {
            stickyClass = "not-dark";
          }
          return stickyClass;
        })

        const logoClass = computed(() => {
          if(computedOptions.style == "square") {
            // return "square-logo";
          }
          return "";
        })

        const showCart = computed(() => {
          return computedOptions.topCartOptions != undefined;
        })

        const currentLanguageCode = computed(() => {
          return store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        })

        const isLoggedIn = computed(() => {
          return store.getters['authentication/' + authenticationTypes.getters.IS_LOGGED_IN];
        })

        const routes = computed(() => {
          var app = getApp();
          var routes = store.getters['application/' + applicationTypes.getters.GET_ROUTES_IN_MENU](app, "primary");
          return routes;
        })

        onMounted(() => {
            
        })

        onBeforeUnmount(() => {
            
        })

        const onMenuTrigger = (event:Event) => {
          // We toggle mobile menu with body css class
          store.commit('meta/' + metaTypes.mutations.TOGGLE_BODY_CLASS, 'primary-menu-open');
        }

        return {
            headerCssClass,
            stickyClass,
            logoClass,
            isDarkHeader,
            logoURL: computedOptions.logoURL,
            darkLogoURL: computedOptions.darkLogoURL,
            showCart,
            topCartOptions: computedOptions.topCartOptions,
            labels: props.labels,
            currentLanguageCode,
            isLoggedIn,
            routes,
            onMenuTrigger
        }

    }
})
</script>